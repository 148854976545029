import React from "react";
import { Link, useNavigate} from "react-router-dom";
import Logo from '../assets/logo/logo.png'
const NotFoundPage =() => {

    const Navigate = useNavigate();

    const handleClick = () => {
        Navigate("/")
      };
    return(
        <main>
            <section className="home_parent">

                <div className="child">
                <div className="logo"></div>
                <div className="heading">
                      Page  Not Found
                </div>
                
                <div className="continue-button-div" style={{ marginTop: "20px" }}>
                
                    <button type="button" onClick={handleClick} >Go to Login</button>
                </div>
                </div>

</section>
        </main>
        
    )
}

export default NotFoundPage