import Axios from "axios";
import { id } from "date-fns/locale";
import { language } from "../../src/helpers/Utils";
import {useSearchParams} from "react-router-dom";
const base_url = process.env.REACT_APP_API_URL;
const org_instance = process.env.REACT_APP_ORG_INSTANCE_ID;

const defaultOptions = {
  headers: {
    "Authorization": "Bearer " + localStorage.getItem("token"),
    "Content-Type": "application/json",
    "Accept-Language": language()
  }
};
export const instance = Axios.create(defaultOptions);

export const ORG_AUTH_CHANGE_PASSWORD = "/auth/admin/change-password";
export const PARKING_AIRPORT_ADD = "/parking/airport/add"
export const PARKING_AIRPORT_UPDATE = "/parking/airport/update/";
export const PARKING_AIRPORT_DELETE = "/parking/airport/delete/";
export const  PARKING_AIRPORT_GET = "/parking/airport/get/";
export const PARKING_AIRPORT_LIST = "/parking/airport/list"

export const PARKING_LOCATION_LIST = "/parking/location/list";
export const PARKING_LOCATION_ADD = "/parking/location/add";
export const PARKING_LOCATION_UPDATE = "/parking/location/update/";
export const PARKING_LOCATION_DELETE = "/parking/location/delete/";
export const PARKING_LOCATION_GET = "/parking/location/get/";

// export const PARKING_LOCATION_DELETE = "/parking/location/delete/";

export const PARKING_LOCATION_PRICE_LIST= "/parking/location-price/list";
export const PARKING_LOCATION_NEW_PRICE_LIST = "parking/location/price/list";
export const PARKING_LOCATION_PRICE_DELETE = "/parking/location-price/delete/";

export const PARKING_LOCATION_PRICE_ADD= "/parking/location-price/add"; 
export const PARKING_LOCATION_PRICE_GET = "parking/location/price/get/"
export const PARKING_LOCATION_PRICE_NEW_DELETE = "/parking/location/price/delete/";

export const PARKING_BOOKING_LIST = "/parking/booking/list";
export const PARKING_LOCATION_PUBLIC_LIST = "parking/location/public/list";

export const PARKING_BOOKING_ADD = "/parking/booking/add";
export const PARKING_BOOKING_GET = "/parking/booking/get/";

export const PARKING_BOOKING_STATUS_LIST = "/parking/booking/status/list";
export const PARKING_BOOKING_STATUS_UPDATE = "/parking/booking/status/update/"
export const PARKING_BOOKING_UPDATE = "/parking/booking/update/";

export const PARKING_BOOKING_STATISTICS_IN_OUT_LIST = "/parking/booking/statistics/in-out/list"

export const PARKING_BOOKING_STATISTICS_BY_SEGMENT = "/parking/booking/statistics-by-segment";
export const PARKING_TRIPS_STATISTICS_BY_SEGMENT = "/parking/trips/statistics-by-calender";
export const PARKING_BOOKING_STATISTICS_LIST = "/parking/booking/statistics/list"

export const AUTH_GET_DETAILS_BY_TOKEN = "/auth/get-details-by-token"
export function getAuthDetailsByToken(value) {
  return Axios.get(AUTH_GET_DETAILS_BY_TOKEN, value);
}

export const PARKING_BOOKING_DETAILS = "/parking/booking/get/amount-details/list"
// export function getParkingDetails(value) {
//   return Axios.get(PARKING_BOOKING_DETAILS, value);
// }

export function updatePassword(value) {
  return Axios.patch(ORG_AUTH_CHANGE_PASSWORD, value);
}

export function addParkingAirport(value){
  return Axios.post(PARKING_AIRPORT_ADD, value)
}

export function getAirportList(){
  return Axios.get(PARKING_AIRPORT_LIST+"?status=1")
}

export function updateParkingAirport(id, value){
  return Axios.patch(PARKING_AIRPORT_UPDATE+id, value)
}

export function deleteParkingAirport(id){
  return Axios.delete(PARKING_AIRPORT_DELETE+id)
}

export function getParkingAirport(id){
  return Axios.get(PARKING_AIRPORT_GET+id);
}

export function addParkingLocation(value){
  return Axios.post(PARKING_LOCATION_ADD, value)
}

export function updateParkingLocation(id, value){
  return Axios.patch(PARKING_LOCATION_UPDATE+id, value)
}

export function deleteParkingLocation(id){
  return Axios.delete(PARKING_LOCATION_DELETE+id)
}

export function getParkingLocation(id){
  return Axios.get(PARKING_LOCATION_GET+id);
}

export function deleteLocationPrice(id){
  return Axios.delete(PARKING_LOCATION_PRICE_DELETE+id);
}

export function addLocationPrice(value){
  return Axios.post(PARKING_LOCATION_PRICE_ADD, value)
}

export function getLocationPrice(id){
  return Axios.get(PARKING_LOCATION_PRICE_GET+id+"/")
}

export function deleteLocationPriceNew(id){
  return Axios.delete(PARKING_LOCATION_PRICE_NEW_DELETE+id)
}

export function getParkingBookingList(){
  return Axios.get(PARKING_BOOKING_LIST)
}

export function getLocationPublicList(type,airport_id){
  return Axios.get(PARKING_LOCATION_PUBLIC_LIST+"&page_size=1000"+"&type="+type+"&airport_id="+airport_id)
}

export function getLocationList(type,airport_id){
  return Axios.get(PARKING_LOCATION_LIST+"?status=1"+"&page_size=1000"+"&type="+type+"&airport_id="+airport_id)
}

export function addParkingBooking(value){
  return Axios.post(PARKING_BOOKING_ADD, value)
}

export function getParkingBooking(id){
  return Axios.get(PARKING_BOOKING_GET+id)
}

export function parkingBookingStatusList(){
  return Axios.get(PARKING_BOOKING_STATUS_LIST)
}

export function parkingBookingStatusUpdate(id, value){
  return Axios.patch(PARKING_BOOKING_STATUS_UPDATE+id, value)
}

export function parkingBookingUpdate(id, value){
  return Axios.patch(PARKING_BOOKING_UPDATE+id, value)
}

export function parkingBookingStatisticsInOutList(type,date){
  return Axios.get(PARKING_BOOKING_STATISTICS_IN_OUT_LIST+"?type="+type +"&date=" + date)
}

export function parkingBookingStatisticsBySegment(type){
  return Axios.get(PARKING_BOOKING_STATISTICS_BY_SEGMENT+"?segment="+type)
}

export function parkingTripStatisticsByCalender(type){
  return Axios.get(PARKING_TRIPS_STATISTICS_BY_SEGMENT+"?segment="+type)
}

export function parkingBookingStatisticsList(){
  return Axios.get(PARKING_BOOKING_STATISTICS_LIST)
}

// Admin

export const PARKING_ADMIN_LIST = "/company/driver&admin/list"


export const PARKING_ADMIN_UPDATE = "/company/driver/admin-status/update/"
export function updateParkingAdmin(id, value) {
  return Axios.patch(PARKING_ADMIN_UPDATE+id, value);
}


export const PARKING_ADMIN_DELETE = "auth/admin/delete/"
export function deleteParkingAdmin(id){
  return Axios.delete(PARKING_ADMIN_DELETE+id)
}


// Packages

export const PARKING_PACKAGE_LIST = "/parking/package/list"
export function getParkingPackageList(days) {
  return Axios.get(PARKING_PACKAGE_LIST+"?page_size="+days);
}

//Flight Tracking
export const PARKING_FLIGHT_TRACKING_LIST = "/parking/flight-tracking/list"

 
 export function getCurrentCompanyId()
{
  if (localStorage.getItem("company_id") === "null" || localStorage.getItem("company_id") === "undefined") {
    
        localStorage.setItem("company_id", localStorage.getItem('company_id')) 
    }
  else if(localStorage.getItem("company_id") === "null" || localStorage.getItem("company_id") === "undefined")
  {
       Navigate("/notfound")
  }
  return (localStorage.getItem("company_id"))
}

export const PARKING_PACKAGE_ADD = "/parking/package/add"
export function getParkingPackageAdd(value) {
  return Axios.post(PARKING_PACKAGE_ADD, value);
}

export const PARKING_PACKAGE_UPDATE = "/parking/package/update/"
export function updateParkingPackage(id, value) {
  return Axios.patch(PARKING_PACKAGE_UPDATE+id, value);
}

export const PARKING_PACKAGE_GET = "/parking/package/get/"
export function getParkingPackage(id) {
  return Axios.get(PARKING_PACKAGE_GET+id);
}

export const PARKING_PACKAGE_DELETE = "/parking/package/delete/"
export function deleteParkingPackage(id) {
  return Axios.delete(PARKING_PACKAGE_DELETE+id);
}

export const PARKING_BOOKING_SEND_MAIL = "/parking/booking/send-mail/"
export function parkingBookingSendMail(id) {
  return Axios.get(PARKING_BOOKING_SEND_MAIL+id);
}

export const PARKING_LOCATION_PRICE_PUBLIC_LIST = "/parking/location-price/admin/list"
export function parkingLocationPricePublicList(departureId, arrivalId, departureDate, arrivalDates,airport_id,company) {
  return Axios.get(PARKING_LOCATION_PRICE_PUBLIC_LIST+"?departure_id="+departureId+"&arrival_id="+arrivalId+"&departure_date="+departureDate+"&arrival_date="+arrivalDates+"&airport_id="+airport_id+"&company="+company);
}

export const PARKING_PAYMENT_LIST = "/parking/payment/list";

export const PARKING_PAYMENT_ADD = "/parking/payment/add"
export function parkingPaymentAdd(value) {
  return Axios.post(PARKING_PAYMENT_ADD, value);
}

export const PARKING_PAYMENT_DELETE = "/parking/payment/delete/"
export function parkingPaymentDelete(id) {
  return Axios.delete(PARKING_PAYMENT_DELETE+id);
}

// Park And Ride Booking

export const PARK_AND_RIDE_BOOKING_LIST = "/parking/park-ride/booking/list";
export function getParkAndRideBookingList(){
  return Axios.get(PARK_AND_RIDE_BOOKING_LIST)
}
export const PARK_AND_RIDE_BOOKING_ADD = "/parking/park-ride/booking/add";
export function addParkAndRideBooking(value){
  return Axios.post(PARK_AND_RIDE_BOOKING_ADD, value)
}

// /Core Agency

export const PARKING_COREAGENCY_LIST = "/parking/coreagency/list";
export function getCoreAgencyList(){
  return Axios.get(PARKING_COREAGENCY_LIST)
}

export const PARKING_COREAGENCY_ADD = "/parking/coreagency/add"
export function parkingCoreAgencyAdd(value) {
  return Axios.post(PARKING_COREAGENCY_ADD, value);
}
export const PARKING_COREAGENCY_UPDATE = "/parking/coreagency/update/"
export function updateParkingCoreAgency(id, value) {
  return Axios.patch(PARKING_COREAGENCY_UPDATE+id, value);
}

export const PARKING_COREAGENCY_GET = "/parking/coreagency/get/"
export function getParkingCoreAgency(id) {
  return Axios.get(PARKING_COREAGENCY_GET+id);
}

export const PARKING_COREAGENCY_DELETE = "/parking/coreagency/delete/"
export function deleteParkingCoreAgency(id) {
  return Axios.delete(PARKING_COREAGENCY_DELETE+id);
}

//Agency
export const PARKING_AGENCY_ADD = "parking/agency/add"
export function getParkingAgencyAdd(value) {
  return Axios.post(PARKING_AGENCY_ADD, value);
}
export const PARKING_AGENCY_UPDATE = "/parking/agency/update/"
export function updateParkingAgency(id, value) {
  return Axios.patch(PARKING_AGENCY_UPDATE+id, value);
}

export const PARKING_AGENCY_GET = "/parking/agency/get/"
export function getParkingAgency(id) {
  return Axios.get(PARKING_AGENCY_GET+id);
}

export const PARKING_AGENCY_DELETE = "/parking/agency/delete/"
export function deleteParkingAgency(id) {
  return Axios.delete(PARKING_AGENCY_DELETE+id);
}

export const PARKING_AGENCY_LIST = "/parking/agency/list";
export function getAgencyList(){
  return Axios.get(PARKING_AGENCY_LIST)
}





//Yard
export const PARKING_YARD_ADD = "parking/yards/add"
export function addParkingYard(value) {
  return Axios.post(PARKING_YARD_ADD, value);
}
export const PARKING_YARD_UPDATE = "/parking/yards/update/"
export function updateParkingYard(id, value) {
  return Axios.patch(PARKING_YARD_UPDATE+id, value);
}

export const PARKING_YARD_GET = "/parking/yards/get/"
export function getParkingYard(id) {
  return Axios.get(PARKING_YARD_GET+id);
}

export const PARKING_YARD_DELETE = "/parking/yards/delete/"
export function deleteParkingYard(id) {
  return Axios.delete(PARKING_YARD_DELETE+id);
}

export const PARKING_YARD_LIST = "/parking/yards/list";
export function getYardList(){
  return Axios.get(PARKING_YARD_LIST+"?status=1")
}


//Band
export const PARKING_BAND_ADD = "parking/band/add"
export function addParkingBand(value) {
  return Axios.post(PARKING_BAND_ADD, value);
}

export const PARKING_BAND_DELETE = "/parking/band/delete/"
export function deleteParkingBand(id) {
  return Axios.delete(PARKING_BAND_DELETE+id);
}

export const PARKING_BAND_LIST = "/parking/band/list";
export function getBandList(){
  return Axios.get(PARKING_BAND_LIST)
}


export const PARKING_BAND_TYPE = "/parking/band/type";
export function getBandType(){
  return Axios.get(PARKING_BAND_TYPE)
}


//Band Month
export const PARKING_BAND_MONTH_ADD = "parking/band-month/add"
export function addParkingBandMonth(value) {
  return Axios.post(PARKING_BAND_MONTH_ADD, value);
}
export const PARKING_BAND_MONTH_UPDATE = "/parking/band-month/update/"
export function updateParkingBandMonth(id, value) {
  return Axios.patch(PARKING_BAND_MONTH_UPDATE+id, value);
}

export const PARKING_BAND_MONTH_GET = "/parking/band-month/get/"
export function getParkingBandMonth(id) {
  return Axios.get(PARKING_BAND_MONTH_GET+id);
}

export const PARKING_BAND_MONTH_DELETE = "/parking/band-month/delete/"
export function deleteParkingBandMonth(id) {
  return Axios.delete(PARKING_BAND_MONTH_DELETE+id);
}

export const PARKING_BAND_MONTH_LIST = "/parking/band-month/list";
export function getBandMonthList(){
  return Axios.get(PARKING_BAND_MONTH_LIST)
}

export const PARKING_MONTH_LIST = "/parking/month/list";
export function getMonthList(){
  return Axios.get(PARKING_MONTH_LIST)
}



//Documents
export const PARKING_DOCUMENT_ADD = "parking/document/add"
export function addParkingDocument(value) {
  return Axios.post(PARKING_DOCUMENT_ADD, value);
}
export const PARKING_DOCUMENT_UPDATE = "/parking/document/update/"
export function updateParkingDocument(id, value) {
  return Axios.patch(PARKING_DOCUMENT_UPDATE+id, value);
}

export const PARKING_DOCUMENT_GET = "/parking/document/get/"
export function getParkingDocument(id) {
  return Axios.get(PARKING_DOCUMENT_GET+id);
}

export const PARKING_DOCUMENT_DELETE = "/parking/document/delete/"
export function deleteParkingDocument(id) {
  return Axios.delete(PARKING_DOCUMENT_DELETE+id);
}

export const PARKING_DOCUMENT_LIST = "/parking/document/list";
export function getDocumentList(){
  return Axios.get(PARKING_DOCUMENT_LIST+"?status=1")
}


//Driver
export const PARKING_DRIVER_ADD = "/auth/driver/add"
export function addParkingDriver(value) {
  return Axios.post(PARKING_DRIVER_ADD, value);
}
export const PARKING_DRIVER_LOGIN = "/auth/driver/login"
export function DriverLogin(value) {
  return Axios.post(PARKING_DRIVER_LOGIN, value);
}

export const PARKING_DRIVER_FORGET_PASSWORD = "auth/driver/forget-password"
export function DriverForgetPassword(value) {
  return Axios.post(PARKING_DRIVER_FORGET_PASSWORD, value);
}

export const PARKING_DRIVER_CHANGE_PASSWORD = "/auth/driver/change-password"
export function DriverChangePassword(value) {
  return Axios.patch(PARKING_DRIVER_CHANGE_PASSWORD, value);
}

export const PARKING_DRIVER_LIST = "/driver/list";
export function getDriverList(){
  return Axios.get(PARKING_DRIVER_LIST+"?page_size=1000&approve_status=approved")
}

export const PARKING_DRIVER_STATUS_LIST = "/company/status/list";
export function getDriverStatusList(){
  return Axios.get(PARKING_DRIVER_STATUS_LIST)
}

export const PARKING_DRIVER_STATUS_UPDATE = "/driver/update/status/";
export function driverStatusUpdate(id,value){
  return Axios.patch(PARKING_DRIVER_STATUS_UPDATE + id ,value)
}

export const PARKING_DRIVER_LOGOUT = "/auth/driver/logout";
export function DriverLogout(value){
  return Axios.patch(PARKING_DRIVER_LOGOUT, value);
} 

export const PARKING_DRIVER_UPDATE = "/driver/update/"
export function updateParkingDriver(id, value) {
  return Axios.patch(PARKING_DRIVER_UPDATE+id, value);
}

export const PARKING_DRIVER_GET = "/driver/get/"
export function getParkingDriver(id) {
  return Axios.get(PARKING_DRIVER_GET+id);
}

export const PARKING_DRIVER_DELETE = "/driver/delete/"
export function deleteParkingDriver(id) {
  return Axios.delete(PARKING_DRIVER_DELETE+id);
}

//admin

export const ADMIN_LIST = "/auth/admin/list";
export function getAdminList(){
  return Axios.get(ADMIN_LIST)
}


//payout

export const PARKING_PAYOUT_ADD = "/parking/driver/payouts/add"
export function addParkingPayout(value) {
  return Axios.post(PARKING_PAYOUT_ADD, value);
}

export const PARKING_PAYOUT_LIST = "/parking/driver/payouts/list";
export function getPayoutList(){
  return Axios.get(PARKING_PAYOUT_LIST)
}

export const PARKING_PAYOUT_UPDATE = "/parking/driver/payouts/update/"
export function updateParkingPayout(id, value) {
  return Axios.patch(PARKING_PAYOUT_UPDATE+id, value);
}

export const PARKING_PAYOUT_GET = "/parking/driver/payouts/get/"
export function getParkingPayout(id) {
  return Axios.get(PARKING_PAYOUT_GET+id);
}

export const PARKING_PAYOUT_DELETE = "/parking/driver/payouts/delete/"
export function deleteParkingPayout(id) {
  return Axios.delete(PARKING_PAYOUT_DELETE+id);
}

export const PARKING_PAYOUT_PAYMENT_DETAILS = "parking/driver-payout/statistics/list"
export function getPayoutPaymentList(){
  return Axios.get(PARKING_PAYOUT_PAYMENT_DETAILS)
}

export const PARKING_PARTICULAR_PAYOUT_PAYMENT_DETAILS = "parking/driver-payout/statistics/list?driver_id="
export function getParticularPayoutPaymentList(id){
  return Axios.get(PARKING_PARTICULAR_PAYOUT_PAYMENT_DETAILS+id)
}

// blogs 

export const PARKING_BLOG_ADD = "parking/blog/add"
export function addParkingBlogs(value) {
  return Axios.post(PARKING_BLOG_ADD, value);
}

export const PARKING_BLOG_GET = "parking/blog/get/"
export function getParkingBlogs(id) {
  return Axios.get(PARKING_BLOG_GET+id);
}

export const PARKING_BLOG_DELETE = "parking/blog/delete/"
export function deleteParkingBlogs(id) {
  return Axios.delete(PARKING_BLOG_DELETE+id);
}

export const PARKING_BLOG_UPDATE = "parking/blog/update/"
export function updateParkingBlogs(id, value) {
  return Axios.patch(PARKING_BLOG_UPDATE+id, value);
}

export const PARKING_BLOG_LIST = "/parking/blog/list";
export function getPayoutBlogs(){
  return Axios.get(PARKING_BLOG_LIST)
}

// reports

export const PARKING_REPORTS_LIST = "parking/booking/statistics/list";
export function getPayoutReports(){
  return Axios.get(PARKING_REPORTS_LIST)
}

//attendance

export const PARKING_DRIVER_ATTENDANCE_ADD = "/parking/attendance/add"
export function addParkingDriverAttendance(value) {
  return Axios.post(PARKING_DRIVER_ATTENDANCE_ADD, value);
}

export const PARKING_DRIVER_ATTENDANCE_LIST = "/parking/attendance/list";
export function getDriverAttendanceList(){
  return Axios.get(PARKING_DRIVER_ATTENDANCE_LIST)
}

export const PARKING_DRIVER_ATTENDANCE_UPDATE = "/parking/attendance/update/"
export function updateDriverAttendance(id, value) {
  return Axios.patch(PARKING_DRIVER_ATTENDANCE_UPDATE+id, value);
}

export const PARKING_DRIVER_ATTENDANCE_PAYMENT_STATUS_UPDATE = "parking/attendance/update/paid_status/";
export function driverAttendancePaymentStatusUpdate(id,value){
  return Axios.patch(PARKING_DRIVER_ATTENDANCE_PAYMENT_STATUS_UPDATE + id ,value)
}

export const PARKING_DRIVER_ATTENDANCE_STATUS_UPDATE = "parking/attendance/update/attendance_status/";
export function driverAttendanceStatusUpdate(id,value){
  return Axios.patch(PARKING_DRIVER_ATTENDANCE_STATUS_UPDATE + id ,value)
}

export const PARKING_DRIVER_ATTENDANCE_GET = "/parking/attendance/get/"
export function getDriverAttendance(id) {
  return Axios.get(PARKING_DRIVER_ATTENDANCE_GET+id);
}

export const PARKING_DRIVER_ATTENDANCE_DELETE = "/parking/attendance/delete/"
export function deleteDriverAttendance(id) {
  return Axios.delete(PARKING_DRIVER_ATTENDANCE_DELETE+id);
}


export const PARKING_DRIVER_PAYMENT_DETAILS = "parking/attendance/get/paid_details/list"
export function getPayoutDriverPaymentList(){
  return Axios.get(PARKING_DRIVER_PAYMENT_DETAILS)
}

export const PARKING_PARTICULAR_DRIVER_PAYMENT_DETAILS = "parking/attendance/get/paid_details/list?driver_id="
export function getPayoutParticularDriverPaymentList(id){
  return Axios.get(PARKING_PARTICULAR_DRIVER_PAYMENT_DETAILS+id)
}


// price per hr

export const PARKING_DRIVER_PRICEPERHOUR_LIST = "/parking/driver/price_per_hour/list";


export const PARKING_DRIVER_PRICEPERHOUR_ADD = "/parking/driver/price_per_hour/add"
export function addParkingDriverPriceperHr(value) {
  return Axios.post(PARKING_DRIVER_PRICEPERHOUR_ADD, value);
}

export const PARKING_DRIVER_PRICEPERHOUR_GET = "/parking/driver/price_per_hour/get/"
export function getParkingDriverPriceperHr(id) {
  return Axios.get(PARKING_DRIVER_PRICEPERHOUR_GET+id);
}

// Additional Service

export const PARKING_ADDITINONALSERVICE_LIST = "/parking/additional_services/list"

export function getParkingAdditionalServiceList(){
  return Axios.get(PARKING_ADDITINONALSERVICE_LIST)
}

export const PARKING_ADDITINONALSERVICE_ADD = "/parking/additional_services/add"
export function addParkingAdditionalService(value) {
  return Axios.post(PARKING_ADDITINONALSERVICE_ADD, value);
}

export const PARKING_ADDITINONALSERVICE_GET = "/parking/additional_services/get/"
export function getParkingAdditionalService(id) {
  return Axios.get(PARKING_ADDITINONALSERVICE_GET+id);
}

export const PARKING_ADDITINONALSERVICE_DELETE = "/parking/additional_services/delete/"
export function deleteParkingAdditionalService(id) {
  return Axios.delete(PARKING_ADDITINONALSERVICE_DELETE+id);
}

export const PARKING_ADDITINONALSERVICE_UPDATE = "/parking/additional_services/update/"
export function updateParkingAdditionalService(id, value) {
  return Axios.patch(PARKING_ADDITINONALSERVICE_UPDATE+id, value);
}

// Booking Additional Service

export const PARKING_BOOKINGADDITINONALSERVICE_LIST = "/parking/booking-additional_service/list"

// export function getParkingBookingAdditionalServiceList(){
//   return Axios.get(PARKING_BOOKINGADDITINONALSERVICE_LIST)
// }

export const PARKING_BOOKINGADDITINONALSERVICE_ADD = "/parking/booking-additional_service/add"
export function addParkingBookingAdditionalService(value) {
  return Axios.post(PARKING_BOOKINGADDITINONALSERVICE_ADD, value);
}

export const PARKING_BOOKINGADDITINONALSERVICE_GET = "/parking/booking-additional_service/get/"
export function getParkingBookingAdditionalService(id) {
  return Axios.get(PARKING_BOOKINGADDITINONALSERVICE_GET+id);
}

export const PARKING_BOOKINGADDITINONALSERVICE_DELETE = "/parking/booking-additional_service/delete/"
export function deleteParkingBookingAdditionalService(id) {
  return Axios.delete(PARKING_BOOKINGADDITINONALSERVICE_DELETE+id);
}

export const PARKING_BOOKINGADDITINONALSERVICE_UPDATE = "/parking/booking-additional_service/update/"
export function updateParkingBookingAdditionalService(id, value) {
  return Axios.patch(PARKING_BOOKINGADDITINONALSERVICE_UPDATE+id, value);
}

// Blogs


// export const PARKING_BLOGS_ADD = "/parking/blog/add"
// export function addParkingBlogs(value) {
//   return Axios.post(PARKING_BLOGS_ADD, value);
// }

// export const PARKING_BLOGS_LIST = "/parking/blog/list";
// export function getBlogsList(){
//   return Axios.get(PARKING_BLOGS_LIST)
// }

// export const PARKING_BLOGS_UPDATE = "/parking/blog/update/"
// export function updateBlogs(id, value) {
//   return Axios.patch(PARKING_BLOGS_UPDATE+id, value);
// }

// export const PARKING_BLOGS_GET = "/parking/blog/get/"
// export function getBlogs(id) {
//   return Axios.get(PARKING_BLOGS_GET+id);
// }

// export const PARKING_BLOGS_DELETE = "/parking/blog/delete/"
// export function deleteBlogs(id) {
//   return Axios.delete(PARKING_BLOGS_DELETE+id);
// }

//Trip
export const PARKING_TRIP_LIST = "/parking/trips/list";
export function getTripList(){
  return Axios.get(PARKING_TRIP_LIST)
}

export const PARKING_TRIP_GET = "/parking/trips/get/"
export function getParkingTrip(id) {
  return Axios.get(PARKING_TRIP_GET+id);
}


export const PARKING_TRIP_UPDATE = "/parking/trips/update/"
export function updateParkingTrip(id, value) {
  return Axios.patch(PARKING_TRIP_UPDATE+id, value);
}

export const PARKING_TRIP_STATUS = "/parking/trips/status/list/";
export function getTripStatusList(){
  return Axios.get(PARKING_TRIP_STATUS)
}

export const PARKING_TRIP_STATUS_UPDATE = "/parking/trips/status/update/admin/"
export function updateParkingTripStatus(id, value) {
  return Axios.patch(PARKING_TRIP_STATUS_UPDATE+id, value);
}

export const PARKING_TRIP_MOVEMENT_ADD = "/parking/trips/movement"
export function addParkingMovement(value) {
  return Axios.post(PARKING_TRIP_MOVEMENT_ADD, value);
}

//Company
export const PARKING_COMPANY_ADD = "/company/add"
export function addParkingCompany(value) {
  return Axios.post(PARKING_COMPANY_ADD, value);
}
export const PARKING_COMPANY_UPDATE = "/company/update/"
export function updateParkingCompany(id, value) {
  return Axios.patch(PARKING_COMPANY_UPDATE+id, value);
}

export const PARKING_COMPANY_GET = "/company/get/"
export function getParkingCompany(id) {
  return Axios.get(PARKING_COMPANY_GET+id);
}

export const PARKING_COMPANY_DELETE = "/company/delete/"
export function deleteParkingCompany(id) {
  return Axios.delete(PARKING_COMPANY_DELETE+id);
}

export const PARKING_COMPANY_LIST = "/company/list";
export function getCompanyList(){
  return Axios.get(PARKING_COMPANY_LIST)
}

// Partners 
export const PARKING_PARTNERS_LIST = "/company/partners/list";

//Driver Document

export const PARKING_DRIVER_DOCUMENT_ADD = "/parking/driver/documents/add"
export function addParkingDriverDocument(value) {
  return Axios.post(PARKING_DRIVER_DOCUMENT_ADD, value);
}

export const PARKING_DRIVER_DOCUMENT_DELETE = "/parking/driver/documents/delete/"
export function deleteParkinDriverDocument(driver_id,document_id) {
  return Axios.delete(PARKING_DRIVER_DOCUMENT_DELETE+ driver_id + "/" + document_id);
}

export const PARKING_DRIVER_DOCUMENT_LIST = "/parking/driver/documents/list";
export function getDriverDocumentList(){
  return Axios.get(PARKING_DRIVER_DOCUMENT_LIST)
}

//core airport
export const PARKING_CORE_AIRPORT_LIST = "/parking/core/airport/list";
export function getCoreAirportList(){
  return Axios.get(PARKING_CORE_AIRPORT_LIST)
}
 //CoreLocation
 export const PARKING_CORE_LOCATION_LIST = "/parking/core/location/list";
export function getCoreLocationList(){
  return Axios.get(PARKING_CORE_LOCATION_LIST)
}


//Voucher
export const PARKING_VOUCHER_ADD = "/parking/voucher/add"
export function addParkingVoucher(value) {
  return Axios.post(PARKING_VOUCHER_ADD, value);
}
export const PARKING_VOUCHER_UPDATE = "/parking/voucher/update/"
export function updateParkingVoucher(id, value) {
  return Axios.patch(PARKING_VOUCHER_UPDATE+id, value);
}

export const PARKING_VOUCHER_GET = "/parking/voucher/get/"
export function getParkingVoucher(id) {
  return Axios.get(PARKING_VOUCHER_GET+id);
}

export const PARKING_VOUCHER_DELETE = "/parking/voucher/delete/"
export function deleteParkingVoucher(id) {
  return Axios.delete(PARKING_VOUCHER_DELETE+id);
}

export const PARKING_VOUCHER_LIST = "/parking/voucher/list";
export function getVoucherList(){
  return Axios.get(PARKING_VOUCHER_LIST)
}

export const USER_LIST = "/user/list";
// export function a
export const GET_COUNTRY_BY_PAGE = "/country/?page_size=1000";
export function getCountryList() {
  return Axios.get(GET_COUNTRY_BY_PAGE);
}
export const HTTP_CODE = {
  ACCEPTED: 202,
  BADREQUEST: 400,
  CONFLICT: 409,
  CREATED: 201,
  OK: 200
};