import React, { useEffect } from "react";
import "./App.css";
import "datatables.net-responsive";
import "./index.css";
import "react-toastify/dist/ReactToastify.css"; // import first
import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import { themeReducer } from "./redux/ThemeReducer";
import { Suspense } from "react";
import { useNavigate } from "react-router";
import "./AuthRoutePage/pcstyle.css"
import { getCurrentCompanyId } from "../src/components/ApiConst";
// import {
//   BrowserRouter,
//   // useRoutes,
// } from "react-router-dom";
// const AuthUI = React.lazy(
//   () => import("@ellantec/pearlcore_auth_ui/dist/AuthRoutePage")
// );
// import AuthRoutePage from '@ellantec/pearlcore_auth_ui/dist/AuthRoutePage';
import { errorToastMsg, getOrgIdLocal } from "./helpers/Utils";
import { ToastContainer } from "react-toastify";
import * as _new from "./components/setupAxios";
import axios from "axios";


import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthPage } from "./modules/Auth";

import Axios from "./redux/setupAxios";
// import { UserRoleReducer as userRole } from "./redux/UserRoleReducer";
import { themeReducer as theme } from "./redux/ThemeReducer";
import NotFoundPage from "./parking pages/NotFoundPage";

const App = React.lazy(() => import("./App"));
document.title = "Parking Solutions";
function Root() {
  const base_url = process.env.REACT_APP_API_URL;
  const ORGANIZATION_ID = getOrgIdLocal();
  const rootReducer = combineReducers({ theme })
  const store = createStore(rootReducer);
  document.title = "Parking Solutions";
  _new.setupAxios(axios, base_url);
  const [isAuthorized, setIsAuthorized] = React.useState("token" in localStorage );
  return !isAuthorized ? (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <ToastContainer />
            <Routes>
              <Route
                path="/auth/*"
                element={
                  <AuthPage
                    org_instance_id={ORGANIZATION_ID}
                    // loginResponce={loginResponce}
                    onLoginSuccess={function (response: any): void {

                      if (response.status === 202) {
                        window.location.href = "/"+getCurrentCompanyId();
                      }
                    }}
                    onLoginFailed={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onSignupFailed={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onSignupSuccess={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onForgotPasswordSuccess={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onForgotPasswordFailed={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onResetPasswordSuccess={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onResetPasswordFailed={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onEmailVerifySuccess={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onEmailVerifyFailed={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onSignupMobileOtpSentSuccess={function (
                      response: any
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                    onSignupMobileOtpSentFailed={function (
                      response: any
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                    node_id={1}
                    base_url={base_url}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/auth/login" />} />
              <Route path="/notfound" element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  ) : (
    <>
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
        </Suspense>
      </Provider>
    </>
  );
}
export default Root;