import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { authLogin, getCountryList } from "../Api/authCrud";
import { ErrorMessage, SuccessMessage } from "../../../components/CustomAlert";
import { HTTP_CODE } from "../../../components/ApiConst";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation, useParams,useSearchParams} from "react-router-dom";
import toplogo from "../../../assets/Images/toplogo.png";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
// import './verification_code_input/style.css'
import { CircularProgress } from "@mui/material";
import { color, style } from "@mui/system";

function Login(props) {
  const initialValues = {
    password: "",
    username: "",
    email: "",
  };

  const phoneInputRef = useRef("");

  // useEffect(() => {
  //   phoneInputRef.current.focus();
  // }, []);
  const { onLoginSuccess, onLoginFailed, org_instance_id, intl, } = props;
  const [ipadd, setipadd] = useState(null);
  const [useagent, setuseagent] = useState(null);
  const [showPhnnumInput, setShowPhnnumInput] = useState(false);
  const [showUsernameInput, setShowUsernameInput] = useState(true);
  const [fetchCountryId, setFetchCountryId] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  // const CompanyId = searchParams.get('company_id');
  // localStorage.setItem("company_id",CompanyId) 
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const { t, i18n } = useTranslation();
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);

    formik.setFieldValue("password", evnt.target.value)
  };
  const togglePassword = (value) => {

    if (value) {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),
    // username: Yup.string()
    //   .min(3, "Minimum 3 characters")
    //   .max(50, "Maximum 50 characters")
    //   .required
    //   // intl.formatMessage({
    //   //   id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //   // })
    //   (),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required
      // intl.formatMessage({
      //   id: "AUTH.VALIDATION.REQUIRED_FIELD",
      // })
      (),
  });
  let location = useLocation();

  const [variant, setVariant] = useState("success");
  const [message, setMessage] = useState("");
  const [snak, setSnak] = React.useState(false);
  const [isPhnNumValue, setisPhnNumValue] = useState(null)
  function snakClose() {
    setSnak(false);
  }

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    validateOnBlur:false,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (initialValues.password == values.password && initialValues.username == values.username) {
        showUserDeleteError("Try with different username/password");
        return;
      }
      enableLoading();
      setTimeout(() => {
        setLoading(true);

        // if (values.password == "Admin@123" && values.username == "ellantec@gmail.com") {
        //   localStorage.setItem("token", "fjhfujhfjhg");
        //   window.location.href = "/";
        // } else {
        //   showUserDeleteError("Invalid User")
        // }

        authLogin(values.password, values.email, org_instance_id, fetchCountryId)
          .then((resp) => {
            setLoading(false)
            // dispatch(Login(resp.data));
            if (resp.status === HTTP_CODE.ACCEPTED) {
              localStorage.setItem("token", resp.data.data.access_token);
              localStorage.setItem("ref_token", resp.data.data.refresh_token);
              localStorage.setItem("company_id", resp?.data?.data?.user_data?.company_id);

              disableLoading();
              // props.login(resp.data);

              onLoginSuccess(resp);
              // SuccessMessage({ message: "Invalid Verification Code!" });

              toast.success("User Login successfully.!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
              });
            } else if (resp.status === HTTP_CODE.OK) {
              history("/auth" + resp.data.data.context, { state: resp.data.data });
            } else {
              initialValues.password = values.password;
              initialValues.username = values.username;

              showUserDeleteError();
            }
          })
          .catch((error) => {
            showUserDeleteError(error?.response?.data?.message);
            if(localStorage.getItem("company_id") === "null" || localStorage.getItem("company_id") === "undefined")
              {
                history("/notfound")
              }
            // setLoading(true)
            // history("/auth" + error.response.data.data.context, { state: error.response.data.data });
            // initialValues.password = values.password;
            // initialValues.username = values.username;

          })
          .finally(() => {
            setLoading(false);
          });

      });
    },
  });
  const showUserDeleteError = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: false,
      // hideProgressBar: true,
      closeOnClick: true,
    });
  };

  function LoginSubmit() {
    formik.setFieldTouched("username");
    if (formik.touched["username"] && !formik.errors["username"]) {
      formik.handleSubmit();
    }
  }

  const handlechanged = (data) => {
    let isPhoneNumber = /([A-Za-z])/gm;

    let startdigit = /^\d/gm;
    let startCountryCodeSymbol = /^\+\d/gm;

    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      (!isPhoneNumber.test(data) && startdigit.test(data)) ||
      startCountryCodeSymbol.test(data)
    ) {
      setisPhnNumValue(data)
      setShowUsernameInput(false);
      setShowPhnnumInput(true);
    } else if (regEmail.test(data)) {
      setShowUsernameInput(true);
      setShowPhnnumInput(false);
      formik.setFieldValue("email", data);
    } else {
      setShowUsernameInput(true);
      setShowPhnnumInput(false);
      formik.setFieldValue("email", data);
    }
  };
  const handleOnChangedd = (...args) => {
    // ref.current.focus();
    if (args[3] == "") {
      setShowUsernameInput(true)
      setShowPhnnumInput(false);

    }
    var countryCode = "+" + args[1].dialCode;
    var phnNum = args[0].match(/\d{10}$/);

    formik.setFieldValue("username", phnNum);
    // formik.setFieldValue("phn_nbr", phnNum);
    // formik.setFieldValue("country_id", countryCode);
    getCountryCode(countryCode);
  };
  const getCountryCode = (countryCode) => {
    getCountryList().then((res) => {
      var data = res.data.results;
      var matchid = countryCode;

      data.forEach(function (element) {
        if (element?.dial_code == matchid) {
          setFetchCountryId(element?.id);
        }
      });
    });
  };


  return (
    <>
      <div className="login-form login-signin" id="kt_login_signin_form">
        <div class="stu-logo-img">

          {/* <img src={toplogo} alt="Paris" style={{width:"100%"}}/> */}

        </div>
        <div className="text-left mb-10 " style={{ fontSize: "24px", fontWeight: "600" }}>
          {t('Welcome')}
        </div>
        <div style={{ textAlign: "center" }}>

          <img width={"100%"} style={{ marginBottom: "30px" }} src={props?.webConfig?.config?.login_page_logo} />
      </div>
        {/* end::He/ad */}

        {/*begin::Form*/}
        <form
          onSubmit={LoginSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <div className="show-user-input">
            <div className="form-group fv-plugins-icon-container">
              {/* <div className=" react-tel-input form-control form-control-lg form-control-solid"> */}
              <input
                placeholder={t(`Email`)}
                autoFocus="true"
                autocomplete="off"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
                onChange={(e) => handlechanged(e.target.value)}
              />
              {formik.touched.email  && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <small className="text-danger">{formik.errors.email}</small>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        <div className="form-group fv-plugins-icon-container">
            {/* <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              onInput={() => formik.setFieldTouched("password", true)}
              {...formik.getFieldProps("password")}
            /> */}
   {/* <div className="pc-input-group"> */} {/* <div className="pc-input-group-prepend"> */}
         <input
              className="form-control form-control-solid h-auto py-5 px-6 "
              placeholder={t(`Password`)}
              // type="password"
              name="password"
              // onInput={() => formik.setFieldTouched("password", true)}
              // {...formik.getFieldProps("password")}

              type={passwordType}
              onChange={handlePasswordChange}
              value={passwordInput}
            />
            {/* <span className="pc-input-group-text">
            <div className="icon-color"><i class="fa fa-eye"></i></div>
          </span> */}
            {/* </div> */}
            {/* </div> */}


            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <small className="text-danger">{formik.errors.password} </small>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center fs-14" >
            <div>
              <input type="checkbox" name="ShowPassword" onClick={(e) => togglePassword(e.target.checked)} />
              <label for="ShowPassword"> {t(`Show Password`)}</label></div>

            {/* <Link
              to="/auth/forgot-password"
              id="kt_login_forgot"
              style={{ color: "#2874f0" }}
            >
              {t(`Forgot Password`)} ?
            </Link> */}


          </div>

          <LoadingButton
            style={{ width: "112%", padding: "12px" }}
            id="kt_login_signin_submit"
            type="submit"
            loading={loading}
            loadingPosition="center"
            loadingIndicator={<CircularProgress style={{ 'color': 'white', 'size': '10px' }} />}
            onClick={formik.handleSubmit}
            className={`btn btn-diff-clr fw-bold px-9 py-4 my-3 login-button-sty-button `}
          >
            <span style={{fontSize:"17px"}}>{t(`Sign In`)}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </LoadingButton>
        </form>

      </div>
      {/* </FadeIn> */}
    </>
  );
}

export default Login;
