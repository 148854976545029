import React, { useState, useEffect } from "react";
import { Link, Navigate, Routes, Route } from "react-router-dom";
// import { toAbsoluteUrl } from "@ellantec/pearlcore_config/dist/_pearl/_helpers";
// import { ContentRoute } from "../../../../_pearl/layout";
import Login from "./Login";
import { useLocation } from "react-router-dom";
import { getServiceInstance } from "../Api/authCrud"
import LoadingSpinner from "../../../components/LoaderSmall";
// import { VerificationLinkPhone } from "../../../User/verification/VerificationLinkPhone";
import {
  Navbar,
  NavbarRight,
  NavIcon,
  P,
  Bold,
  NavbarLeft,
  NavBranch,
  NavLoading,
} from "../../../style";
import Box from '@mui/material/Box';
import { Menu, IconButton, CircularProgress, FormControl, InputLabel } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from 'react-i18next';
import  LoginSideImg  from '../../../assets/logo/cheep_logo.png'



export function AuthPage({
  onLoginSuccess,
  onLoginFailed,
  onSignupFailed,
  onSignupSuccess,
  onForgotPasswordSuccess,
  onForgotPasswordFailed,
  onResetPasswordSuccess,
  onResetPasswordFailed,
  onEmailVerifySuccess,
  onEmailVerifyFailed,
  onSignupMobileOtpSentSuccess,
  onSignupMobileOtpSentFailed,
  org_instance_id,
  node_id,
  base_url,
  // configDataResp
}) {
  const [loading, setLoading] = useState(true);
  const [webConfigData, setwebConfigData] = React.useState(null);
  const [acceptLanguage, setAcceptLanguage] = React.useState(localStorage.getItem("language") || "en");
  const [language, setLanguage] = React.useState(localStorage.getItem("language"));
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language]);

  // useEffect(()=>{
  //   setAcceptLanguage(localStorage.getItem("language"))
  // },[localStorage.getItem("language")])

  const handleChange = (event) => {
    localStorage.setItem("language", event.target.value);
    setAcceptLanguage(event.target.value);
    window.location.reload();
  };
  useEffect(() => {
    setLoading(true);
  }, []);


  const toAbsoluteUrl = base_url
  let location = useLocation();
  var configData = localStorage.getItem("persist:config");
  var webConfig = JSON.parse(configData)

  return (
    <>
      {/* {loading && <LoadingSpinner />} */}
      <div className=
        {`d-flex flex-column container flex-root h-vh ${loading ? "d-none" : ""}`}
      >
        <div
          className=" login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >

          {/* 1 */}
          {/* <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 col1"
            style={{
              
              background: "rgb(232 245 254 / 74%)"
            }}
          >
            <div className="d-flex flex-row-fluid flex-column justify-content-center align-items-center">
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <img
                  alt="Logo"
                  style={{width:"100%",objectFit:"cover"}}
                
                  src={LoginSideImg}
                />
              </Link>

              <div className="d-none flex-column-auto  copy d-lg-flex" >
                <div className="opacity-70 	text-white  pt-5 mt-1 " style={{paddingTop:"190px"}}> 
                
                </div>
               
              </div>
            </div>
          </div> */}

          {/* 2 */}

          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

            <NavbarRight style={{ justifyContent: "right" }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={acceptLanguage}
                    label=""
                    onChange={handleChange}
                  // sx={{padding:"10px 14px"}}
                  >
                    <MenuItem value={"en"}>English</MenuItem>
                    <MenuItem value={"ta"}>தமிழ்</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </NavbarRight>

            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Routes>
                <Route
                  exact
                  path="/login"
                  element={
                    <Login
                      org_instance_id={org_instance_id}
                      onLoginSuccess={onLoginSuccess}
                      onLoginFailed={onLoginFailed}
                      webConfig={webConfig}
                    />
                  }
                />
                <Route path="/" element={<Navigate to="/auth/login" />} />
              </Routes>
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}
